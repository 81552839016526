//@ts-nocheck
import * as actionTypes from '../actions/actionTypes';
import {
  postApiLoggedInOrderPdf,
  postApiOpenOrderPdf,
} from '../../services/api/form/order.api';
import { clearBasketLocalStorage } from '../../utils';
import { resetOrderForm, restoreInitialOrderForm } from './orderFormActions';
import { checkIfUserComIsEnabled } from '../../userCom/utilis/checkIfUserComIsEnabled';
import { userComOrderEvent } from '../../userCom/events/order';
import { userComUpdateUserEvent } from '../../userCom/users/update';
import { salesManagoRegisterEvent } from '../../salesManago/events/register';
import { checkIsSalesManagoEnabled } from '../../salesManago/helpers';
import { setModalErrorLoading } from '../../features/modalError/services/redux/modalError.slice';
import { formApi } from '../../services/api/form';
import { purchaseGA4 } from '../../features/GA4/events/ecommerceEvents';
import { getFinalSumAfterDiscountAndDeliveryFee } from '../selectors/basketSelector';
import { getIsKuchniaVikinga } from '@features/general/generalSlice';
import { PurchaseGA4ArgsT } from '@features/GA4/types/GA4.type';

const setLoyaltyProgramPointsSessionStorage = data => {
  sessionStorage.setItem('loyaltyProgramPoints', JSON.stringify(data));
};

export const storeLoyaltyPointsSum = (points, companyId) => ({
  companyId,
  points,
  type: actionTypes.STORE_LOYALTY_POINTS_SUM,
});

export const storeProfile = (profile, cities, zipCodes) => ({
  type: actionTypes.STORE_PROFILE,
  profile,
  cities,
  zipCodes,
});
export const markProfileAsFetched = () => ({
  type: actionTypes.MARK_PROFILE_FETCHED,
});

export const storePaymentUrl = paymentUrl => ({
  type: actionTypes.STORE_PAYMENT_URL,
  paymentUrl,
});

export const placeLoggedInOrderPdf =
  (order, file, navigate, sendGTMTransactionEvents) => (dispatch, getState) => {
    const cateringName = formApi.endpoints.getApiFormSettings.select()(
      getState()
    )?.data?.generalInformation?.cateringName;
    const isKuchniaVikinga = getIsKuchniaVikinga(getState());

    const {
      basketReducer: {
        basket,
        promoCode: { code },
      },
      general: {
        config: {
          server: { apiKey, companyId, enhancedEcommerce, serverUrl, userCom },
        },
      },
      profileReducer: {
        profile: { firstName, lastName },
      },
      summaryReducer: {
        methodOfPurchase,
        newAddressDetails: { city, street, zipCode },
        paymentType,
      },
    } = getState();
    const finalAmountToPayAfterDiscountsAndDeliveryFee =
      getFinalSumAfterDiscountAndDeliveryFee(getState());

    dispatch({ type: actionTypes.RESET_PAYMENT_URL });
    dispatch(setModalErrorLoading(true));

    const bodyFormData = new FormData();
    bodyFormData.append(
      'newOrderRequest',
      new Blob([JSON.stringify(order)], {
        type: 'application/json',
      }),
      'newOrderRequest'
    );
    fetch(file)
      .then(res => res.blob())
      .then(blob => {
        const pdfFile = new File([blob], 'GROUPON.pdf', {
          type: 'application/pdf',
        });
        bodyFormData.append('pdfAttachment', pdfFile);

        return postApiLoggedInOrderPdf(
          bodyFormData,
          serverUrl,
          companyId,
          apiKey
        ).then(response => {
          sessionStorage.removeItem('zero-cost-form');

          const {
            data: {
              clientId,
              clientLifecycleState,
              loyaltyProgramSummary: {
                appliedLoyaltyProgramPoints,
                appliedLoyaltyProgramPointsGlobal,
                loyaltyProgramPointsBalance,
                loyaltyProgramPointsGlobalBalance,
              } = {},
              migratedAddresses,
              orders,
              previousOrdersNumber,
            },
            status,
          } = response;

          if (status === 200) {
            setLoyaltyProgramPointsSessionStorage({
              appliedLoyaltyProgramPoints,
              appliedLoyaltyProgramPointsGlobal,
              loyaltyProgramPointsBalance,
              loyaltyProgramPointsGlobalBalance,
              companyName: cateringName,
            });

            const {
              client: {
                allowMarketingMessages,
                email,
                phoneNumber,
                taxId,
              } = {},
            } = migratedAddresses[0] || {};

            if (checkIfUserComIsEnabled(userCom)) {
              userComOrderEvent(basket, code, city, response.data, userCom);
              userComUpdateUserEvent(
                userCom,
                {
                  allowMarketingMessages,
                  email,
                  phoneNumber,
                  taxId,
                  name: firstName,
                  surname: lastName,
                },
                methodOfPurchase,
                code
              );
            }

            const newOrderIds = orders.map(item => item.orderId);
            const { shoppingCartId } = orders[0] || {};
            let purchaseGA4ArgsList: PurchaseGA4ArgsT = {
              basket,
              city,
              totalPrice: finalAmountToPayAfterDiscountsAndDeliveryFee,
              coupon: code,
              shoppingCartId,
              clientLifecycleState,
              email,
              clientId,
              loggedIn: true,
              previousOrdersNumber,
              typeOfPayment: paymentType,
            };
            if (isKuchniaVikinga) {
              purchaseGA4ArgsList = {
                ...purchaseGA4ArgsList,
                first_name: firstName,
                last_name: lastName,
                street,
                postal_code: zipCode.code,
                phone_number: phoneNumber,
              };
            }

            sendGTMTransactionEvents(
              newOrderIds,
              previousOrdersNumber,
              clientId,
              shoppingCartId,
              clientLifecycleState,
              email
            );

            if (enhancedEcommerce) {
              purchaseGA4(purchaseGA4ArgsList);
            }

            clearBasketLocalStorage();
            dispatch(resetOrderForm());
            dispatch(restoreInitialOrderForm());
            sessionStorage.setItem(
              'order-id-form',
              JSON.stringify(orders[0].orderId)
            );
            navigate('/zaplacono', { replace: true });
            return;
          }
          return Promise.reject(response);
        });
      });
  };

export const placeOpenOrderPdf =
  (order, file, navigate, sendGTMTransactionEvents) => (dispatch, getState) => {
    const cateringName = formApi.endpoints.getApiFormSettings.select()(
      getState()
    )?.data?.generalInformation?.cateringName;
    const isKuchniaVikinga = getIsKuchniaVikinga(getState());

    const {
      basketReducer: {
        basket,
        promoCode: { code },
      },
      general: {
        config: {
          server: {
            apiKey,
            companyId,
            enhancedEcommerce,
            salesManago,
            serverUrl,
            userCom,
          },
        },
      },
      summaryReducer: {
        methodOfPurchase,
        newAddressDetails: { city },
        newClientDetails: { companyName, name, phone, surname },
        paymentType,
      },
    } = getState();
    const finalAmountToPayAfterDiscountsAndDeliveryFee =
      getFinalSumAfterDiscountAndDeliveryFee(getState());

    const {
      newProfile: { email },
    } = order;
    dispatch({ type: actionTypes.RESET_PAYMENT_URL });
    dispatch(setModalErrorLoading(true));

    const bodyFormData = new FormData();
    bodyFormData.append(
      'newOrderRequest',
      new Blob([JSON.stringify(order)], {
        type: 'application/json',
      }),
      'newOrderRequest'
    );

    fetch(file)
      .then(res => res.blob())
      .then(blob => {
        const pdfFile = new File([blob], 'GROUPON.pdf', {
          type: 'application/pdf',
        });

        bodyFormData.append('pdfAttachment', pdfFile);

        return postApiOpenOrderPdf(
          bodyFormData,
          serverUrl,
          companyId,
          apiKey
        ).then(response => {
          sessionStorage.removeItem('zero-cost-form');

          const {
            data: {
              clientId,
              clientLifecycleState,
              loyaltyProgramSummary: {
                appliedLoyaltyProgramPoints,
                appliedLoyaltyProgramPointsGlobal,
                loyaltyProgramPointsBalance,
                loyaltyProgramPointsGlobalBalance,
              } = {},
              migratedAddresses,
              orders,
              previousOrdersNumber,
            },
            status,
          } = response;

          if (status === 200) {
            setLoyaltyProgramPointsSessionStorage({
              appliedLoyaltyProgramPoints,
              appliedLoyaltyProgramPointsGlobal,
              loyaltyProgramPointsBalance,
              loyaltyProgramPointsGlobalBalance,
              companyName: cateringName,
            });

            const {
              client: {
                allowMarketingMessages,
                email: clientEmail,
                phoneNumber,
                taxId,
              } = {},
            } = migratedAddresses[0] || {};

            const { shoppingCartId } = orders[0] || {};
            const newOrderIds = orders.map(item => item.orderId);

            let purchaseGA4ArgsList: PurchaseGA4ArgsT = {
              basket,
              city,
              totalPrice: finalAmountToPayAfterDiscountsAndDeliveryFee,
              coupon: code,
              shoppingCartId,
              clientLifecycleState,
              email,
              clientId,
              previousOrdersNumber,
              loggedIn: false,
              typeOfPayment: paymentType,
            };

            if (isKuchniaVikinga) {
              purchaseGA4ArgsList = {
                ...purchaseGA4ArgsList,
                first_name: name,
                last_name: surname,
                street,
                postal_code: zipCode.code,
                phone_number: phoneNumber,
              };
            }

            sendGTMTransactionEvents(
              newOrderIds,
              previousOrdersNumber,
              clientId,
              shoppingCartId,
              clientLifecycleState,
              email
            );

            if (enhancedEcommerce) {
              purchaseGA4(purchaseGA4ArgsList);
            }

            if (checkIfUserComIsEnabled(userCom)) {
              userComOrderEvent(basket, code, city, response.data, userCom);
              userComUpdateUserEvent(
                userCom,
                {
                  allowMarketingMessages,
                  email: clientEmail,
                  phoneNumber,
                  taxId,
                  name,
                  surname,
                },
                methodOfPurchase,
                code
              );
            }

            if (checkIsSalesManagoEnabled(salesManago) && order.signUp) {
              salesManagoRegisterEvent(
                {
                  allowMarketingMessages,
                  firstName: name,
                  lastName: surname,
                  phone,
                  companyName,
                  email,
                },
                salesManago,
                serverUrl
              ).catch(e => {
                console.log(e, 'e');
              });
            }

            sessionStorage.setItem(
              'order-id-form',
              JSON.stringify(orders[0].orderId)
            );
            clearBasketLocalStorage();
            dispatch(resetOrderForm());
            dispatch(restoreInitialOrderForm());
            navigate('/zaplacono', { replace: true });
            return;
          }
          return Promise.reject(response);
        });
      });
  };
