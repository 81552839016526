import { DietlyOpenCityT } from '@services/api/form/open/formOpenCities.api';
import { BasketItemT } from 'typesRoot/basketItem.type';

export enum AddToOrRemoveFromCartE {
  Add = 'add_to_cart',
  Remove = 'remove_from_cart',
}

export type PurchaseGA4ArgsT = {
  basket: Array<BasketItemT>;
  city: DietlyOpenCityT;
  clientId: number;
  clientLifecycleState: string;
  crm_transaction_id?: string;
  coupon: string;
  email: string;
  first_name?: string;
  last_name?: string;
  loggedIn: boolean;
  phone_number?: string;
  postal_code?: string;
  previousOrdersNumber: number;
  shoppingCartId: number;
  street?: string;
  totalPrice: number;
  typeOfPayment: string;
};

export type GtagT = {
  currency: string;
  value: number;
  items: any[];
  coupon: string;
  shipping: number;
  tax: number;
  transaction_id: number;
  user_email: string;
  user_id: number;
  user_logged_in: number;
  new_client: number;
  orders_cnt: number;
  affiliation: string;
  payment_method: string;
  address?: {
    first_name?: string;
    last_name?: string;
    street?: string;
    city?: string;
    region?: string;
    postal_code?: string;
  };
};
